import Cookies from 'js-cookie';

const AccessTokenKey = 'Admin-Token';
const RefreshTokenKey = 'Admin-Token-Refresh';
const TokenExpireKey = 'Admin-Token-Expire';

export function getTokenAccess() {
  return Cookies.get(AccessTokenKey);
}
export function getTokenRefresh() {
  return Cookies.get(RefreshTokenKey);
}
export function getTokenExpire() {
  return Cookies.get(TokenExpireKey);
}

export function setTokenAccess(accessToken) {
  return Cookies.set(AccessTokenKey, accessToken, {
    expires: 1 / 24, // 1時間
  });
}

export function setTokenRefresh(refreshToken) {
  return Cookies.set(RefreshTokenKey, refreshToken, {
    expires: 14, // 2週間(14日間)
  });
}

export function setTokenExpire(expire) {
  return Cookies.set(TokenExpireKey, expire);
}

export function removeTokenAccess() {
  return Cookies.remove(AccessTokenKey);
}
export function removeTokenRefresh() {
  return Cookies.remove(RefreshTokenKey);
}
export function removeTokenExpire() {
  return Cookies.remove(TokenExpireKey);
}

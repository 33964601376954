export default {
  route: {
    siteTitle: 'プードル(POODL)',
    login: 'ログイン',
    organization: '組織',
    about: '開発元について',
    dashboard: 'ダッシュボード',
    introduction: 'イントロダクション',
    documentation: 'ドキュメンテーション',
    storage: 'ストレージ',
    category: 'カテゴリー',
    categoryDetail: 'カテゴリー詳細',
    categoryDetailTitle: '{name} | カテゴリー詳細',
    label: 'ラベル',
    dataset: 'データセット',
    datasetDetail: 'データセット詳細',
    datasetDetailTitle: '{name} | データセット詳細',
    datasetDetailImage: 'データセット詳細',
    datasetDetailEdit: 'データセット詳細編集',
    datasetDetailEditTitle: '{name} | データセット詳細編集',
    datasetCreate: 'データセット新規作成',
    dataSeparation: 'データ分割',
    library: 'ライブラリ',
    annotation: 'アノテーション',
    annotationDetail: 'アノテーション画像一覧',
    annotationDetailTitle: '{name} | アノテーション画像一覧',
    annotationDetailImage: 'アノテーション画像詳細',
    annotationDetailImageTitle: '{name}[{key}] | アノテーション画像詳細',
    preprocessing: '前処理',
    assignment: 'データの分割',
    compute: 'コンピュート',
    computeJob: '学習ジョブ',
    computeJobCreate: '学習ジョブの開始',
    computeJobDetail: '学習ジョブ詳細',
    computeJobDetailTitle: '{name} | 学習ジョブ詳細',
    computeJobDetailImage: '学習画像データ',
    computeJobOutput: '学習ジョブ出力',
    computeJobLog: '学習ジョブのログ',
    computeJobReport: '学習ジョブのレポート',
    computeCondition: '学習条件',
    computeConditionCreate: '学習条件の新規作成',
    computeConditionEdit: '学習条件の編集',
    computeConditionDetail: '学習条件の詳細',
    computeConditionDetailTitle: '{name} | 学習条件の詳細',
    computeNetwork: 'モデルネットワーク',
    computeNetworkUpload: 'モデルネットワークのアップロード',
    computeNetworkCreate: 'モデルネットワークの新規作成',
    computeNetworkDetail: 'モデルネットワークの詳細',
    computeNetworkDetailTitle: '{name} | モデルネットワークの詳細',
    computeLibrary: 'モデルライブラリ',
    computeLibraryCreate: 'モデルライブラリの新規作成',
    computeLibraryDetail: 'モデルライブラリの詳細',
    computeLibraryDetailTitle: '{name} | モデルライブラリの詳細',
    computeJobCheckValidate: 'バリデーションを行う',
    job: 'ジョブ',
    analysis: '分析',
    guide: 'ガイド',
    permission: '権限テストページ',
    pagePermission: 'ページ権限',
    directivePermission: 'インストラクション権限',
    icons: 'アイコン',
    components: 'コンポーネント',
    componentIndex: '紹介',
    tinymce: 'Tinymce',
    markdown: 'マークダウン',
    jsonEditor: 'JSON編集',
    splitPane: 'スプリットペイン',
    avatarUpload: 'アバターをアップロード',
    dropzone: 'ドロップゾーン',
    countTo: 'countTo',
    componentMixin: 'ミックスイン',
    backToTop: 'トップへ戻る',
    dragDialog: 'ドラッグダイアログ',
    charts: 'チャート',
    keyboardChart: 'キーボードチャート',
    lineChart: 'ラインチャート',
    mixChart: 'ミックスチャート',
    example: 'エグザンプル',
    nested: 'ネスティッドルート',
    menu1: 'メニュー1',
    'menu1-1': 'メニュー1-1',
    'menu1-2': 'メニュー1-2',
    'menu1-2-1': 'メニュー1-2-1',
    'menu1-2-2': 'メニュー1-2-2',
    'menu1-3': 'メニュー1-3',
    menu2: 'メニュー2',
    Table: 'テーブル',
    dynamicTable: 'ダイナミックテーブル',
    dragTable: 'ドラッグテーブル',
    inlineEditTable: 'テーブル内エディタ',
    complexTable: '総合テーブル',
    tab: 'タブ',
    form: 'フォーム',
    createArticle: '文章作成',
    editArticle: '文章編集',
    articleList: '文章リスト',
    error: 'エラー!',
    errorPages: 'エラーページ',
    page401: '401',
    page404: '404',
    errorLog: 'エラーログ',
    zip: 'ジップ',
    exportZip: 'ジップをエクスポート',
    theme: 'テーマを変える',
    clipboardDemo: 'クリップボード',
    i18n: '国際化',
    name: '名称',
    description: '備考',
    created: '作成',
    createdAt: '作成日時',
    started: '開始',
    startedAt: '開始日時',
    updated: '更新',
    updatedAt: '更新日時',
    analysisUpdated: '解析処理の更新日時',
    update: '更新',
    updateAndRun: '更新して実行',
    register: '登録',
    actions: 'アクション',
    revision: 'リビジョン',
    imageCount: '画像数',
    origin: '生成元',
    originJob: '生成元ジョブ',
    size: 'サイズ',
    progress: '進捗',
    show: '表示',
    status: 'ステータス',
    operated: '操作',
    exit: '終了コード',
    condition: '学習条件',
    finished: '終了',
    elapsed: '経過',
    list: '一覧',
    previous: '戻る',
    next: '進む',
    create: '作成',
    createAndRun: '作成して実行',
    run: '実行',
    upload: 'アップロード',
    tag: 'タグ',
    key: 'キー',
    layerCount: '画像数',
    weight: '重み値',
    image: '画像',
    imageTitle: '{name}[{key}] | 画像',
    layer: 'レイヤー',
    ok: 'OK',
    cancel: 'キャンセル',
    remove: '削除',
    archive: 'アーカイブ',
    restoreArchived: 'アーカイブを元に戻す',
    edit: '編集',
    copy: 'コピー',
    dropFileHere: 'ドロップかクリックでファイルをアップロード',
    network: 'ネットワーク',
    comment: 'コメント',
    file: 'ファイル',
    content: 'コンテンツ',
    inherited: '継承元',
    exitCode: '終了コード',
    format: 'フォーマット',
    data: 'データ',
    attribute: '属性',
    download: 'ダウンロード',
    order: '順序',
    color: '色',
    setting: '設定',
    clear: 'クリア',
    displayLatestRevision: '最新リビジョンを表示',
    username: 'ユーザーネーム',
    memo: 'メモ',
    role: '権限',
    settings: '設定',
    settingsProfile: 'プロフィール',
    settingsUser: 'ユーザー設定',
    settingsGroup: 'グループ設定',
    settingsUtilization: '利用状況',
    settingsHistory: '履歴',
    settingsSecurity: 'セキュリティ',
    registerUser: 'ユーザー登録',
    close: '閉じる',
    append: '追加',
    answerResult: '正解',
    predictResult: '推論',
    errValidationFailed: 'データが不正です！',
    loading: '読込中...',
    processing: '処理中...',
    yearMonth: '年月',
    copyToClipboard: 'クリップボードにコピー',
    msgCopiedToClipboard: 'クリップボードにコピーしました！',
    searchResult: '検索結果: {keyword}',
    reload: '更新',
    currentlySelectedDataset: '選択中のデータセット',
    metadata: 'メタデータ',
    free: '空き容量',
    sec: '秒.',
    success: '成功',
    template: 'テンプレート',
    date: {
      yearsBefore: '{years}年前',
      monthsBefore: '{months}ヶ月前',
      weeksBefore: '{weeks}週間前',
      daysBefore: '{days}日前',
      hoursBefore: '{hours}時間前',
      minsBefore: '{mins}分前',
      secsBefore: '{secs}秒前',
    },
    getOutputListFailed: '出力リストの取得に失敗しました',
    unknownClass: 'その他分類',
  },
  dashboard: {
    createDataset: 'データセット作成',
    showDataset: 'データセット一覧',
    goAnnotation: 'アノテーション作業',
    runLearning: '学習の開始',
  },
  category: {
    count: 'カテゴリー数',
    createCategory: 'カテゴリー新規作成',
    imageCount: '画像数',
    editCategory: 'カテゴリーの編集',
    deleteCategory: 'カテゴリー削除',
    createLabel: 'ラベルの新規作成',
    editLabel: 'ラベルの編集',
    downloadLabelCSV: 'ラベル情報をCSVでダウンロード',
    deleteLabel: 'ラベルの削除',
    search: 'カテゴリーを検索',
    add: 'カテゴリーの追加',
    deleteCategoryDescription: 'カテゴリーを削除しても宜しいですか？',
    changeLabelOrderSuccess: 'カテゴリーの順序変更に成功しました',
    changeLabelOrderFailed: 'カテゴリーの順序変更に失敗しました',
    getCategoryFailed: 'カテゴリーの取得に失敗しました',
    getCategoryListFailed: 'カテゴリーのリストの取得に失敗しました',
    addCategoryFailed: 'カテゴリーの追加に失敗しました',
    addCategorySuccess: 'カテゴリーの追加に成功しました',
    editCategoryFailed: 'カテゴリーの編集に失敗しました',
    editCategorySuccess: 'カテゴリーの編集に成功しました',
    deleteCategoryFailed: 'カテゴリーの削除に失敗しました',
    deleteCategorySuccess: 'カテゴリーの削除に成功しました',
    addLabelFailed: 'ラベルの追加に失敗しました',
    addLabelSuccess: 'ラベルの追加に成功しました',
    updateLabelFailed: 'ラベルの更新に失敗しました',
    updateLabelSuccess: 'ラベルの更新に成功しました',
    deleteLabelFailed: 'ラベルの削除に失敗しました',
    deleteLabelSuccess: 'ラベルの削除に成功しました',
    countLabel: 'ラベル数',
  },
  dataset: {
    editing: '編集中',
    count: 'データセット数',
    createDataset: 'データセット新規作成',
    name: 'データセット名',
    imageCount: '画像数',
    selectCategory: 'カテゴリーを選択',
    removeCategory: 'カテゴリーを削除',
    noCategory: 'カテゴリが何も作成されていません！',
    noSelectCategory: 'カテゴリが何も選択されていません！',
    noSelectLabel: '変更ラベルは全て選択してください！',
    goEdit: '編集を開始',
    goAnnotation: 'アノテーションを開始',
    takeOverLabels: 'アノテーション情報を引き継ぐ',
    goAssignment: 'データの分割',
    goCompute: '学習処理を開始',
    goReCategorize: 'カテゴリー変更',
    doneEditing: '編集を完了',
    doneEditingDescription: '編集モードを本当に終了して宜しいですか？',
    editMode: '編集モード',
    editModeDescription: '編集モードへ切り替えても宜しいですか？',
    annotationMode: 'アノテーションモード',
    annotationModeDescription: 'アノテーションモードに切り替えても宜しいですか？',
    changeCategoryMode: 'カテゴリー変更モード',
    changeCategoryModeDescription: '変更したいカテゴリーを選択してください',
    noChangeCategory: 'カテゴリーが変更されていません!',
    currentCategory: '現在のカテゴリー',
    changeCategory: '変更するカテゴリー',
    assignmentDescription: 'データの分割を行っても宜しいですか？',
    getAssignmentCountFailed: '割り当て数の取得に失敗しました',
    editSetting: 'データセット編集の設定',
    groupHead: 'グループ化先頭文字数',
    groupingMultiImage: '先頭文字数${}が一致するファイルをグループ化します',
    deleteDataset: 'データセット削除',
    deleteAllDataset: 'データセット一括削除',
    deleteAllDatasetWarning: 'データセット一括削除機能は関連する全てのデータセットが削除されます!',
    detail: 'データセット詳細情報',
    eachCategory: '各分類項目の中で均等に分割',
    editDataset: 'データセット編集',
    editModeInitKey: 'データセットを複製しますか？新しいキーが生成されます',
    rateOfTraining: '教師データ割合',
    downloadAnnotationList: 'アノテーション情報一覧をダウンロード',
    downloadCompress: 'データセットを一括でダウンロード',
    msgConfirmCreateDownload: 'ダウンロード用のデータセット圧縮処理を行いますか？',
    msgCreateDownload:
      'ダウンロード用ファイルの準備を開始しました。完了時にEメールにて通知を行います。',
    errCreateDownload: 'ダウンロード用ファイルの作成に失敗しました。',
    annotationListType: {
      both: 'アップロードファイル名 + サーバー識別名',
      bothDescription: 'アップロードファイル名とサーバー上の識別名をアンダースコアで連結した文字列',
      remotePath: 'サーバー識別名',
      remotePathDescription: 'サーバー上の学習処理のための識別名',
      localName: 'アップロードファイル名',
      localNameDescription: 'アップロードした際のファイル名',
    },
    deleteDatasetConfirm: 'データセットを削除しても宜しいですか？',
    search: 'データセットを検索',
    deleteAllDatasetsConfirm:
      'このデータセット及び関連するデータセットを全て削除してよろしいですか？',
    getDatasetFailed: 'データセットの取得に失敗しました',
    addDatasetFailed: 'データセットの追加に失敗しました',
    addDatasetSuccess: 'データセットの追加に成功しました',
    editDatasetFailed: 'データセットの編集に失敗しました',
    editDatasetSuccess: 'データセットの編集に成功しました',
    deleteDatasetFailed: 'データセットの削除に失敗しました',
    deleteDatasetSuccess: 'データセットの削除に成功しました',
    findDatasetFailed: 'データセットが見つかりませんでした',
    beginEditFailed: '編集の開始に失敗しました',
    beginAnnotationFailed: 'アノテーションの開始に失敗しました',
    noImage: '画像がありません',
    splittingDataFailed: '割り当て（データ分割）を実行できませんでした',
    findUploadingFileFailed: 'アップロードファイルが存在しません',
    wrongUploadingFile: 'アップロードファイルに問題があります',
    changeCategoryFailed: 'カテゴリーの変更に失敗しました',
    changeCategorySuccess: 'カテゴリーの変更に成功しました',
    uploadImageFailed: '画像のアップロードに失敗しました!!',
    deleteImageFailed: '画像の削除に成功しました',
    finishEditOperationFailed: '編集操作を終了できませんでした',
    finishEditModeFailed: '編集モードを終了できませんでした',
    divideDatasetSuccess: 'データセットの分割に成功しました',
    divideDatasetFailed: 'データセットの分割に失敗しました',
    concatenateDatasetSuccess: 'データセットの結合に成功しました',
    concatenateDatasetFailed: 'データセットの結合に失敗しました',
    simpleEdit: '簡易編集',
  },
  preprocessing: {
    concat: '結合',
    concatDatasets: 'データセットを結合 ({count})',
    concatConfirm: 'これらのデータセットを結合してよろしいですか？',
    delete: '削除',
    deleteDatasets: 'データセットを削除',
    deleteOneDatasetConfirm: 'このデータセットを削除してよろしいですか？',
    deleteDatasetsConfirm: 'これらのデータセットを削除してよろしいですか？',
    divide: '分割',
    divideDataset: 'データセットを分割',
    divideDatasetMessage:
      'このデータセットは、以下の表にあるラベルごとのデータセットに分割されます',
    divideConfirm: 'このデータセットを分割してよろしいですか？',
    transform: '変換',
    transformDataset: 'データセットを変換',
    transformDatasetMessage:
      'このデータセットのラベルのデータは、以下の表で選択したラベルとして統合されます',
    transformConfirm: 'このデータセットのラベルを変換してよろしいですか？',
    transformProcessSuccess: 'データセットのラベル変換が完了しました',
    transformProcessFailed: 'データセットのラベル変換に失敗しました',
    deleteOnTransform: 'このラベルを消去',
    toLabel: '変換先',
    transformNewName: '変換後のデータセット',
  },
  annotation: {
    count: 'アノテーション中のデータセット数',
    imageCount: '画像数',
    cancel: 'キャンセル',
    category: 'カテゴリー',
    done: 'アノテーション作業完了',
    doneDescription: '本当にアノテーション作業を終了しても宜しいですか？',
    doneFailed: 'アノテーション作業に失敗しました',
    continueFailed: '全ての画像のラベル割り当てが完了しています',
    addLabelFailed: 'ラベルの追加に失敗しました',
    continue: '続きから始める',
    filename: 'ファイル名',
    autoScrollOn: '自動スクロールオン',
    autoScrollOff: '自動スクロールオフ',
    getImageFailed: '画像の取得に失敗しました',
    getCategoryFailed: 'カテゴリーの取得に失敗しました',
    detachLabelFailed: 'ラベルの変更に失敗しました',
    cannotGetLabelCount: 'ラベル数の取得に失敗しました',
  },
  compute: {
    job: {
      count: 'ジョブ数',
      startJob: 'ジョブの開始',
      copyJob: 'ジョブ条件をコピー',
      name: 'ジョブ名',
      edit: 'ジョブの編集',
      confirmCancel: '本当に学習ジョブをキャンセルしますか？',
      confirmRemove: '本当に学習ジョブを削除しますか？',
      detail: 'ジョブの詳細情報',
      summary: 'サマリー',
      process: 'プロセス',
      confusion_matrix: '混同行列',
      twodmap: '2次元マップ',
      stat: '統計値',
      answerLabel: '正解ラベル',
      predictLabel: '予測ラベル',
      createLibrary: 'ライブラリを作成',
      confirmCreateLibrary: 'この学習済みモデルからライブラリを作成してもいいですか？',
      errOutputClassNum: 'データセットのクラス数とネットワークの出力が一致していません。',
      errInputChannel: 'データセットの入力チャンネル数とネットワークの入力が一致していません。',
      newName: '{prev} のコピー',
      errGetList: 'ジョブの一覧が取得できません。',
      search: '学習ジョブを検索',
      label: 'ラベル名',
      correctLabelNum: '正解ラベル数',
      incorrectLabelNum: '不正解ラベル数',
      correctRate: '正解率',
      pdf: 'PDF出力',
      recall: '再現率(recall)基準',
      precision: '適合率(precision)基準',
      findJobFailed: 'ジョブが見つかりませんでした',
      findJobPredictFailed: 'ジョブの予測情報の取得に失敗しました',
      findJobDatasetImageFailed: 'ジョブのデータセット画像の取得に失敗しました',
      deleteJobFailed: 'ジョブの削除に失敗しました',
      cancelJobFailed: 'ジョブの中断に失敗しました',
      cancelJobSuccess: 'ジョブの中断に成功しました',
      updateJobFailed: 'ジョブの更新に失敗しました',
      updateJobSuccess: 'ジョブの更新に成功しました',
      createJobFailed: 'ジョブの作成に失敗しました',
      archiveJobFailed: 'ジョブのアーカイブに失敗しました',
      restoreArchivedJobFailed: 'ジョブのアーカイブを元に戻せませんでした',
      runJobSuccess: 'ジョブを実行しました',
      runJobFailed: 'ジョブの実行に失敗しました',
      getLogFailed: 'ログの取得に失敗しました',
      trainAccLabel: 'トレーニング精度',
      ValAccLabel: 'バリデーション精度',
      MacroFLabel: 'マクロ F値',
    },
    condition: {
      count: '学習条件数',
      create: '学習条件を作成',
      name: '学習条件名',
      confirmRemove: '本当に学習条件を削除しますか？',
      goCompute: '学習処理を開始',
      search: '学習条件を検索',
      simpleEditing: '簡易編集',
      detailEditing: '詳細編集',
      simpleInfo: '簡易情報',
      detailInfo: '詳細情報',
      epoch: 'エポック',
      height: '高さ',
      width: '幅',
      inputDataSize: '入力データサイズ',
      channels: 'チャンネル数',
      learningSchedule: '学習率スケジュール',
      initialLearningRate: '初期値',
      learningStep: 'ステップ',
      learningRate: '変化率',
      createConditionFailed: '学習条件の作成に失敗しました',
      submitFailed: '送信に失敗しました',
      submitSuccess: '送信に成功しました',
      getConditionInformationFailed: '学習条件の情報取得に失敗しました',
      deleteConditionFailed: '学習条件の削除に失敗しました',
      updateConditionFailed: '学習条件の更新に失敗しました',
      getConditionFailed: '学習条件の取得に失敗しました',
      goCopy: 'コピーを作成',
      goEdit: '編集完了',
    },
    network: {
      count: 'ネットワーク数',
      createFromPreset: 'ネットワークをプリセットから作成',
      createFromJson: 'json形式のネットワークファイルから作成',
      name: 'ネットワーク名',
      structure: 'ネットワーク構造',
      confirmRemove: '本当にネットワークを削除しますか？',
      goCompute: '学習処理を開始',
      preset: '作成するモデル',
      input: '入力',
      inputImageSize: '画像サイズ',
      channel: 'チャンネル',
      output: '出力',
      classnum: 'クラス数',
      character: '字',
      errorNotGettingPresetList: 'ネットワークのリストを取得できませんでした',
      errorNotGettingNetwork: 'ネットワークの構造ファイルを取得できませんでした',
      errorNotCreateNetwork: 'ネットワークの作成に失敗しました',
      successCreateNetwork: 'ネットワークが作成されました！',
      graph: 'ネットワークのグラフ',
      info: 'ネットワークの情報',
      copyStructure: 'クリップボードに構造情報をコピー',
      successCopyStructure: 'クリップボードに構造情報をコピーしました',
      errorNotGetNetworkDetail: 'ネットワークの詳細情報を取得できませんでした',
      errorNotGetNetworkSummary: 'ネットワーク構造の情報を取得できませんでした',
      errorNotGetNetworkGraph: 'ネットワークのグラフ図を取得できませんでした',
      errorNotRemoveNetwork: 'このネットワークは削除できませんでした',
      search: 'ネットワークを検索',
      findNetworkFailed: 'ネットワークが見つかりませんでした',
      getNetworkListFailed: 'ネットワークのリストの取得に失敗しました',
      origin: '作成元ネットワーク',
    },
    library: {
      count: 'ライブラリ数',
      create: 'ライブラリをアップロード',
      name: 'ライブラリ名',
      input: '入力',
      inputImageSize: '画像サイズ',
      channel: 'チャンネル',
      output: '出力',
      classnum: 'クラス数',
      fixedLayers: '重み固定レイヤー',
      fixedLayersEnabled: '重み固定レイヤーの有効',
      fixedLayersDisabled: '重み固定レイヤーの無効',
      confirmRemove: '本当にライブラリを削除しますか？',
      goCompute: '学習処理を開始',
      search: 'ライブラリを検索',
      findLibraryFailed: 'ライブラリが見つかりませんでした',
      submitFailed1: '作成に失敗しました (1)',
      submitFailed2: '作成に失敗しました (2)',
      submitFailed3: '作成に失敗しました (3)',
      submitSuccess: '作成に成功しました',
      unSelectedFile: '選択されていないファイルです',
      deleteLibraryFailed: 'ライブラリの削除に失敗しました',
      getLibraryListFailed: 'ライブラリのリストの取得に失敗しました',
    },
    output: {
      name: '出力ファイル',
      report: '帳票出力',
    },
    log: {
      name: 'ログ',
    },
  },
  navbar: {
    logOut: 'ログアウト',
    dashboard: 'ダッシュボード',
    github: 'github',
    screenfull: 'フルスクリーン',
    theme: 'テーマを変える',
    settings: '設定',
    switchLanguage: '言語を切り替えました',
    screenfullFailed: 'ブラウザの拡大に失敗しました',
  },
  login: {
    title: 'ログインフォーム',
    logIn: 'ログイン',
    organization: '所属',
    username: 'ユーザーネーム',
    password: 'パスワード',
    mailaddress: 'メールアドレス',
    any: '適当に入力',
    thirdparty: '第三者アカウントでログイン',
    thirdpartyTips:
      'ローカルではシュミレーションできません．あなた自身の業務と結合してシュミレーションして下さい．',
    organizationTitle: '組織にログイン',
    organizationSubmit: '次へ',
    backToSelect: '組織入力に戻る',
    resetPass: 'パスワードの再設定',
    registerIpAddress: 'IPアドレスを登録',
    backToLoginForm: 'ログイン画面に戻る',
    sendResetPassMail: 'パスワード再設定メールを送信',
    invalidDataFormat: 'データの形式が異なります',
  },
  documentation: {
    documentation: 'ドキュメンテーション',
    github: 'Githubリポジトリ',
  },
  permission: {
    roles: 'あなたの権限',
    switchRoles: '権限切替',
  },
  guide: {
    description:
      'このガイドページは，初めてプロジェクトに加入する人に役立ちます．簡単にプロジェクトの特徴を紹介できます．デモは以下を基にしています．',
    button: 'ガイドを表示',
  },
  components: {
    documentation: 'ドキュメンテーション',
    tinymceTips:
      'リッチテクストは，管理システムのコア的な機能ですが，たくさんの問題があるところでもあります．リッチテクストの選択の過程で，多くの廻り道をしてしまいました．市場でよくあるリッチテクストを一通り使い，最終的にTinymceを選択しました．詳細はリッチテクストの比較と紹介にて.',
    dropzoneTips:
      '弊社の業務は特殊な需要があり，又qiniuにアップロードするため,第三者を通じず，自社にてのパッケージを選択しました．コードは非常にシンプルであり，具体的なコードはこちらにてご覧いただけます@/components/Dropzone.',
    backToTopTips1:
      'ページがピリセットした位置までスクロールされると,下部右側にトップへ戻るボタンが表示されます',
    backToTopTips2:
      'ボタンのスタイルをカスタマイズできます,表示/非表示,表示される高さ,戻る位置.文字的なヒントが必要な場合は，外部でElementのel-tooltip要素を使用することでヒントを得られます',
    imageUploadTips:
      '私が使用していた際は，vue@1バージョンしかなく,mockjsと互換性がなかったため，自分で改造しました.皆さんがご使用になる際は，オフィシャル版を優先にご使用下さい.',
  },
  table: {
    dynamicTips1: 'ヘッダーを固定し，ヘッダーの系列で排列する',
    dynamicTips2: 'ヘッダーは固定せず，クリックした系列で排列する',
    dragTips1: 'デフォルト系列',
    dragTips2: 'ドラッグ後系列',
    title: 'タイトル',
    importance: '重要性',
    type: 'タイプ',
    remark: '評価',
    search: '検索',
    add: '加える',
    export: 'エクスポート',
    reviewer: 'レビューア',
    id: 'ID',
    date: '日付',
    author: '著者',
    readings: 'ブラウザ数',
    status: 'ステータス',
    actions: '操作',
    edit: '編集',
    publish: 'リリース',
    draft: 'ドラフト',
    delete: '削除',
    cancel: '取消',
    confirm: '確定',
  },
  errorLog: {
    tips: '上方右側のバグアイコンをクリックしてください．',
    description:
      '現在の管理システムは基本的にspa形式となります．ユーザーエクスペリエンスは良くなったのですが，ページエラーが出る可能性も増えました．簡単なミスでもページ全体がデッドロックしてしまう可能性があります．幸い，Vueオフィシャルサイトより異常を見つける方法が提供されているので，サイトにて異常の対応又は異常の報告をすることができます．',
    documentation: 'ドキュメントイントロダクション',
  },
  zip: {
    export: 'エクスポート',
    placeholder: 'ファイル名を入力して下さい(デフォルトはfile)',
  },
  theme: {
    change: 'テーマを変える',
    documentation: 'テーマ変更ドキュメンテーション',
    tips:
      'Tips: navbarのtheme-pickとは異なるテーマを変更する方法であり，それぞれ適用されるシーンが異なります．詳しくは参考資料をご覧下さい．',
  },
  tagsView: {
    close: '閉じる',
    closeOthers: 'その他を閉じる',
    closeAll: '全て閉じる',
  },
  settings: {
    profile: 'プロフィール',
    users: 'ユーザー',
    groups: 'グループ',
    notification: '通知',
    utilization: '利用状況',
    download: 'ダウンロード',
    history: '履歴',
    security: 'セキュリティ',
    primary: 'プライマリー',
    deletePrimaryErrorMessage: 'プライマリーは削除できません',
    deleteOnlyEmailErrorMessage: '削除できません',
    addEmail: 'Eメールアドレスを追加',
    save: '保存',
    addUser: 'ユーザーを追加',
    name: '名前',
    username: 'ユーザーネーム',
    email: 'Eメールアドレス',
    user: 'ユーザー',
    group: 'グループ',
    ip: 'IP',
    role: '権限',
    addGroup: 'グループを追加',
    updateGroup: 'グループ情報を更新',
    memo: 'メモ',
    resource: 'リソース',
    auth: '認証',
    action: 'アクション',
    create: '作成・更新',
    delete: '削除',
    get_info: '確認',
    get_list: '一覧',
    change: '変更',
    permission: '許可',
    permit: '許可する',
    prohibit: '許可しない',
    updateUser: 'ユーザー情報を更新',
    dataset: 'データセット',
    update: '更新',
    edit: '編集',
    annotation: 'アノテーション',
    assignment: '比率設定',
    category: 'カテゴリー',
    batchml: 'ジョブ',
    create_condition: 'コンディション作成',
    delete_condition: 'コンディション削除',
    get_info_condition: 'コンディション情報確認',
    create_library: 'ライブラリ作成',
    delete_library: 'ライブラリ削除',
    get_info_library: 'ライブラリ情報確認',
    create_network: 'ネットワーク作成',
    delete_network: 'ネットワーク削除',
    get_info_network: 'ネットワーク情報確認',
    run_job: '実行',
    get_info_job: 'ジョブ情報確認',
    get_info_output: '結果取得',
    delete_output: '結果削除',
    storage: 'ストレージ',
    preprocess: 'データセット前処理',
    concat: '結合',
    divide: '分割',
    utilInfo: '使用状況',
    storageInfo: 'ストレージ容量',
    storageUsed: '使用済み',
    storageRemaining: '残り {msg}',
    learningTime: '学習時間',
    numOfImgs: '総画像枚数',
    numOfDatasets: 'データセット数',
    numOfUsers: 'ユーザー数',
    transform: '変形',
    password: 'パスワード',
    changePassword: 'パスワードの変更',
    unconfirmed: '仮登録',
    registerPassword: 'パスワードの登録',
    datetime: '日時',
    actionType: '操作タイプ',
    jobCredit: '学習ジョブ時間残高',
    jobBalance: '学習ジョブ利用履歴',
    jobBalanceMonthly: '学習ジョブ月次利用量',
    expiredDatetime: '有効期限',
    enabled: '有効',
    disabled: '無効',
    availableTime: '利用可能時間',
    elapsedTime: '利用時間',
    jobCount: 'ジョブ回数',
    badgetTime: '初期時間',
    userStatus: 'ユーザー状態',
    userEnable: '有効化',
    userDisable: '無効化',
    getProfileFailed: 'プロフィールの取得に失敗しました',
    getStorageInformationFailed: 'ストレージ情報の取得に失敗しました',
    getBalanceListFailed: '残高リストの取得に失敗しました',
    getBalanceMonthlyInformationFailed: '月次利用量の取得に失敗しました',
    userDetail: {
      registerUserSuccess: 'ユーザーの新規登録に成功しました',
      errRegister: 'ユーザーを追加できませんでした。',
      msgUpdate: 'ユーザー情報を更新しました。',
      errUpdate: 'ユーザー情報を更新できませんでした。',
      deleteUserDescription: 'ユーザーを削除しても宜しいでしょうか？',
      deleteUserSuccess: 'ユーザーの削除に成功しました',
      deleteUserFailed: 'ユーザーの削除に失敗しました',
      deleteGroupDescription: 'グループを削除しても宜しいでしょうか？',
      deleteGroupSuccess: 'グループの削除に成功しました',
      deleteGroupFailed: 'グループの削除に失敗しました',
      sendRegistrationEmail: '登録メールが送信されました',
      getMemberListFailed: 'メンバーリストの取得に失敗しました',
      changePasswordFailed: 'パスワードの変更に失敗しました',
      changePasswordSuccess: 'パスワードを変更しました',
    },
    downloadDetail: {
      type: 'タイプ',
      title: '名称',
      status: 'ステータス',
      errDownloadRaw: 'ダウンロードファイルが準備できませんでした。',
      msgCancel: '処理がキャンセルされました。',
      errCancel: '処理がキャンセルできませんでした。',
      msgConfirmCancel: '本当にこの処理をキャンセルしますか？',
      msgDelete: 'ダウンロードファイルが削除されました。',
      errDelete: 'ダウンロードファイルが削除できませんでした。',
      msgConfirmDelete: '本当にこのダウンロードファイルを削除しますか？',
    },
    securityDetail: {
      ipAddress: 'IPアドレス',
      registeredUser: '登録ユーザー',
      createdAt: '登録日',
      updatedAt: '更新日',
      enableOrDisable: '有効/無効',
      buttonDisable: '無効化',
      buttonEnable: '有効化',
      buttonDelete: '削除',
      dialogTitleDisable: 'IPアドレスの無効化',
      dialogMessageDisable: 'IPアドレスを無効化しても宜しいですか？',
      dialogTitleEnable: 'IPアドレスの有効化',
      dialogMessageEnable: 'IPアドレスを有効化しても宜しいですか？',
      dialogTitleDelete: 'IPアドレスの削除',
      dialogMessageDelete: 'IPアドレスを削除しても宜しいですか？',
      titleIpAddressRegistration: 'IPアドレスの登録',
      buttonClear: 'クリア',
      buttonRegister: '登録',
      currentAccessIpAddress: '現在のIPアドレス',
      buttonSetCurrentAccessIpAddress: '設定',
      titleIpAddressList: '登録されたIPアドレス一覧',
      msgRegistered: 'IPアドレス ({ipAddress}) が登録されました',
      errMsgCouldNotRegister: 'IPアドレス ({ipAddress}) は登録出来ません。',
      errMsgCouldNotDisable: 'IPアドレス ({ipAddress}) は無効化出来ません。',
      sendEmail: 'メールを送信しました',
      approveAddIpAddressSuccess: 'Ipアドレス追加承認に成功しました',
      invalidURL: '無効なURLです',
    },
  },
};
